import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MenuItem } from './menu-item.types';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SharedModule } from '@shared/shared.module';

@Component({
    selector: 'ln-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SharedModule]
})
export class MenuItemComponent {
  @Input() item!: MenuItem;
  @Input() opened = false;

  @HostBinding('class.bg-selected') @Input() bgSelected?: boolean;

  @Output() selected = new EventEmitter<MenuItem | null>();

  constructor(private localize: LocalizeRouterService) {}

  onItemCommon(item: MenuItem, event: Event) {
    event.stopPropagation();
    if (item.action) {
      item?.action(item);
    } else if (this.item.items && this.item.items.length) {
      this.opened = !this.opened;
    }
  }

  onLink(item: MenuItem) {
    this.selected.emit(item);
  }

  getLink(item: MenuItem) {
    return item?.link ? this.localize.translateRoute(item?.link) : null;
  }

  getAction(item: MenuItem): (item: MenuItem) => null | void {
    return item?.action ? item?.action : () => null;
  }
  getName(item: MenuItem) {
    return item.name || '';
  }

  getCount(item: MenuItem) {
    return typeof item?.count === 'number' ? (item?.count > 99 ? '99+' : item?.count) : 0;
  }
}
