<div class="menu-item">
  <a
    *ngIf="item?.link"
    class="menu-item__common"
    (click)="onLink(item)"
    [routerLink]="getLink(item)"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: item.link === '/' }"
  >
    <ln-icon *ngIf="item?.icon" [name]="item.icon || ''" class="menu-item__icon" height="24px"></ln-icon>
    <div *ngIf="item?.name" class="menu-item__name">{{ getName(item) | translate }}</div>
    <div class="menu-item__count" *ngIf="item?.count">{{ getCount(item) }}</div>
  </a>
  <div
    *ngIf="!item?.link && item?.name && item?.name !== '*'"
    (click)="onItemCommon(item, $event)"
    class="menu-item__common"
  >
    <ln-icon *ngIf="item?.icon" [name]="item.icon || ''" class="menu-item__icon" height="24px"></ln-icon>
    <div *ngIf="item?.name" class="menu-item__name">
      {{ getName(item) | translate }}
    </div>
    <div class="menu-item__arrow" [class]="{ opened: opened || rla.isActive }" *ngIf="item?.items?.length">
      <ln-icon name="arrow-down" height="7px" width="13px"></ln-icon>
    </div>
    <div class="menu-item__count" *ngIf="item?.count">{{ getCount(item) }}</div>
  </div>
  <div *ngIf="!item?.name" class="menu-item__div"></div>
  <div
    class="menu-item__list"
    [hidden]="!item.items?.length || (!opened && !rla.isActive)"
    routerLinkActive="nested-active"
    #rla="routerLinkActive"
  >
    <ln-menu-item
      [routerLink]="getLink(subItem)"
      class="menu-item__list-item subitem"
      *ngFor="let subItem of item?.items"
      [item]="subItem"
      [bgSelected]="bgSelected"
      (selected)="selected.emit(subItem)"
    ></ln-menu-item>
  </div>
</div>
