import { Injectable } from '@angular/core';
import { mapSectionsTotal, unreadItems } from './client-items.const';
import { ApiMethods2, Sections } from '@app/shared/types';
import { AuthService, HttpService, UserService } from '@app/shared/services';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router } from '@angular/router';
import { ClientTotalEntitiesRequest, ClientTotalEntitiesResult, ClientTotalSections } from '@shared/models/api';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientItemsService {
  unreadItems = unreadItems();

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public localize: LocalizeRouterService,
    public router: Router,
    private httpService: HttpService
  ) {}

  get isClient() {
    return this.userService.isClient;
  }

  getCountUnreadItems(section: Sections.Orders | Sections.Rfq): number {
    return this.unreadItems[mapSectionsTotal[section]];
  }

  getTotalUnreadItems() {
    return this.unreadItems[ClientTotalSections.Total];
  }

  async loadUnreadData() {
    const result = await firstValueFrom(
      this.httpService.get<ClientTotalEntitiesRequest, ClientTotalEntitiesResult>({
        method: ApiMethods2.QueriesUserTotalEntities
      })
    );
    this.unreadItems = result.data;
  }
}
